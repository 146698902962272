body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

* {
    margin: 0;
    padding: 0;
}

.App {
  height: 100%;
}

.margin {
  margin-top: 20px;
}

.landing-brand {
  width: 157px;
  height: 76px;
  margin-top: 10px;
  margin-left: 20px;
}

.vmr-container {
  height: 100vh;
  background-color: black;
}

.notifications-container {
  position: absolute;
  top:0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 2;
  max-width: 100% !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horizontal-center {
  left: 0;
  right: 0;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}

.notifications-popup {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
  height: 35px;
}

.notifications-button {
  right: 10px;
  position: absolute !important;
  background-color: gray !important;
  color: white !important;
}

.notifications-header {
  font-size: 23px;
  color: gray;
  left: 10px;
  position: absolute;

}

.toolbar-horizontal {
  z-index: 10;
  text-align: center;
  position: fixed;
  bottom: 45px;
  width: 100%;
  height: 50px;
  transition: opacity 0.2s ease-in-out;
}

.toolbar-icon-svg {
  filter: invert(100%) sepia(0%) saturate(127%) hue-rotate(109deg) brightness(115%) contrast(100%);
  cursor: pointer;
  height: 100%;
  opacity: 0.8;
  padding: 10px;
}

.toolbar-icon-svg-off {
  filter: invert(46%) sepia(81%) saturate(890%) hue-rotate(83deg) brightness(104%) contrast(100%);
}

.toolbar-icon-svg-red {
  filter: invert(11%) sepia(76%) saturate(6597%) hue-rotate(358deg) brightness(104%) contrast(86%);
}
