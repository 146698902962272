.otherVideoContainer {
  z-index: 1;
  position:fixed;
  bottom: 0;
  min-width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
}

.otherVideo {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myVideo {
  z-index: 101;
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
}

.watermark {
  position: absolute;
  opacity: 0.6;
  font-size: 3em;
  z-index: 100;
  left: 40px;
  bottom: 20px;
}
